<template>
  <div class="parameter-management-container">
    <div class="parameter-management-box">
      <div class="header">
        <span class="title">标准参数</span>
        <el-input v-model="search" placeholder="请输入内容" class="search-input" clearable>
          <template #prefix>
            <el-icon>
              <Search />
            </el-icon>
          </template>
        </el-input>
        <el-button type="primary" @click="showAddDialog" class="add-button">添加参数</el-button>
      </div>
      <div class="table-container">
        <el-table :data="paginatedParameters" class="custom-table" :header-cell-style="{ background: '#F5F6F7' }">
          <el-table-column prop="column1" label="参数代码" width="auto" min-width="20%" align="left"
            header-align="left"></el-table-column>
          <el-table-column prop="column2" label="参数名称" width="auto" min-width="30%" align="left"
            header-align="left"></el-table-column>
          <el-table-column prop="creationTime" label="创建时间" width="auto" min-width="30%" align="left"
            header-align="left"></el-table-column>
          <el-table-column label="操作" width="auto" min-width="20%" align="center" header-align="center">
            <template #default="scope">
              <el-button @click="deleteParameter(scope.$index)" type="button" size="16" class="action1">
                <el-icon>
                  <Delete />
                </el-icon> 删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="pagination-container">
        <el-pagination background layout="prev, pager, next" :total="filteredParameters.length" :page-size="pageSize"
          @current-change="handlePageChange" class="pagination" />
        <div class="pagination-info">
          共 {{ filteredParameters.length }} 条
        </div>
      </div>
    </div>

    <!-- 添加参数对话框 -->
    <el-dialog title="添加参数" v-model="addDialogVisible" width="360px" :style="{ borderRadius: '8px', height: '212px' }">
      <el-form :model="form">
        <el-form-item label="参数代码">
          <el-input v-model="form.paramCode" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="参数名称">
          <el-input v-model="form.paramName" placeholder="请输入"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false" style="width: 88px;">关闭</el-button>
        <el-button type="primary" @click="saveParameter" style="width: 88px;">保存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { Search, Delete } from '@element-plus/icons-vue';

export default {
  components: {
    Search,
    Delete
  },
  data() {
    return {
      search: '',
      addDialogVisible: false,
      form: {
        paramCode: '',
        paramName: ''
      },
      parameters: [
        // 模拟数据
        { column1: '内容1', column2: '内容2', creationTime: '2023-01-01' },
        { column1: '内容8', column2: '内容9', creationTime: '2023-01-02' },
        { column1: '内容15', column2: '内容16', creationTime: '2023-01-03' },
        { column1: '内容22', column2: '内容23', creationTime: '2023-01-04' },
        { column1: '内容29', column2: '内容30', creationTime: '2023-01-05' },
        { column1: '内容36', column2: '内容37', creationTime: '2023-01-06' },
        { column1: '内容43', column2: '内容44', creationTime: '2023-01-07' },
        { column1: '内容50', column2: '内容51', creationTime: '2023-01-08' },
        { column1: '内容57', column2: '内容58', creationTime: '2023-01-09' },
        { column1: '内容64', column2: '内容65', creationTime: '2023-01-10' },
        { column1: '内容71', column2: '内容72', creationTime: '2023-01-11' },
        { column1: '内容78', column2: '内容79', creationTime: '2023-01-12' },
        { column1: '内容85', column2: '内容86', creationTime: '2023-01-13' },
        { column1: '内容92', column2: '内容93', creationTime: '2023-01-14' },
        { column1: '内容99', column2: '内容100', creationTime: '2023-01-15' },
        { column1: '内容106', column2: '内容107', creationTime: '2023-01-16' },
        { column1: '内容113', column2: '内容114', creationTime: '2023-01-17' },
        { column1: '内容120', column2: '内容121', creationTime: '2023-01-18' },
        { column1: '内容127', column2: '内容128', creationTime: '2023-01-19' },
        { column1: '内容134', column2: '内容135', creationTime: '2023-01-20' },
        { column1: '内容141', column2: '内容142', creationTime: '2023-01-21' },
        { column1: '内容148', column2: '内容149', creationTime: '2023-01-22' },
        { column1: '内容155', column2: '内容156', creationTime: '2023-01-23' },
        { column1: '内容162', column2: '内容163', creationTime: '2023-01-24' },
        { column1: '内容169', column2: '内容170', creationTime: '2023-01-25' }
      ],
      currentPage: 1,
      pageSize: 10,
    };
  },
  computed: {
    filteredParameters() {
      if (!this.search) return this.parameters;
      return this.parameters.filter(param =>
        Object.values(param).some(value => value.includes(this.search))
      );
    },
    paginatedParameters() {
      const start = (this.currentPage - 1) * this.pageSize;
      const end = this.currentPage * this.pageSize;
      return this.filteredParameters.slice(start, end);
    }
  },
  methods: {
    showAddDialog() {
      this.addDialogVisible = true;
    },
    saveParameter() {
      this.parameters.push({
        column1: this.form.paramCode,
        column2: this.form.paramName,
        creationTime: new Date().toISOString().split('T')[0]
      });
      this.addDialogVisible = false;
      this.form.paramCode = '';
      this.form.paramName = '';
    },
    handlePageChange(page) {
      this.currentPage = page;
    },
    deleteParameter(index) {
      this.paginatedParameters.splice(index, 1);
      this.parameters.splice((this.currentPage - 1) * this.pageSize + index, 1);
    }
  }
};
</script>

<style scoped>
* {
  box-sizing: border-box;
  /* 确保 padding 不会增加元素的总高度 */
}

.parameter-management-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* 居左布局 */
  background-color: #474747;
  min-height: 100vh;
  font-size: 16px;
  line-height: 20px;
}

.parameter-management-box {
  width: calc(100% - 20px);
  /* 减去左右padding */
  height: calc(100vh - 40px);
  /* 减去上下padding */
  background-color: #F0F3F5;
  border-radius: 12px;
  padding: 20px 20px 0 20px;
  box-sizing: border-box;
  position: relative;
  margin: 20px 20px 20px 0px;
  /* 距离顶部20px */
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.title {
  color: #057AB2;
  font-size: 16px;
  font-weight: 500;
  margin-right: auto;
}

.search-input {
  width: 300px;
  margin-right: 20px;
}

.add-button {
  margin-left: 20px;
  background-color: #057AB2;
  /* 上传模板按钮的背景色 */
  border-color: #057AB2;
}

.add-button:hover {
  background-color: #005f8a;
  /* 上传模板按钮悬停时的背景色 */
  border-color: #005f8a;
}

.dialog-footer {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 10px;
}

.table-container {
  flex: 1;
  overflow: auto;
  position: relative;
}

.custom-table {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 52px;
  /* 为分页预留空间 */
  overflow: auto;
}

.custom-table .action1 {
  border: none;
  color: #057ab2;
  background-color: transparent;
}
.custom-table .action1:hover {
  background-color: rgba(5, 122, 178, 0.25);
  border: 1px solid rgba(5, 122, 178, 0.25);
  color: #057ab2;
}

.header-back {
  background-color: #DCDDDE;
  /* 表头背景色 */
}

.pagination-container {
  height: 52px;
  /* 与表格底部预留空间匹配 */
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.pagination {
  display: flex;
  align-items: center;
}

.pagination-info {
  text-align: center;
  margin-left: 10px;
}
</style>
