<template>
  <div class="dictionary-management-container">
    <div class="dictionary-management-box">
      <div class="left">
        <div class="header">
          <span class="title">字典类型列表</span>
          <el-button type="primary" @click="addType" class="add-button">添加类型</el-button>
        </div>
        <div class="line"></div>
        <el-table :data="types" class="custom-table" :show-header="false">
          <el-table-column prop="name" label="类型名称" width="200" align="center" header-align="center"></el-table-column>
          <el-table-column prop="code" label="类型代码" width="200" align="center" header-align="center"></el-table-column>
          <el-table-column label="操作" align="center" header-align="center" width="150">
            <template v-slot="scope">
              <div class="icon-group">
                <el-button @click="editType(scope.row)" type="button">
                  <el-icon size="14">
                    <Edit />
                  </el-icon>
                  编辑
                </el-button>
                <el-button  @click="deleteType(scope.row)" type="button">
                  <el-icon size="14">
                    <Delete />
                  </el-icon>
                  删除
                </el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="right">
        <div class="header">
          <span class="title">字典项</span>
          <el-button type="primary" @click="addItem" class="add-button">添加字典项</el-button>
        </div>
        <div class="line"></div>
        <div class="table-container">
          <el-table :data="items" :show-header="false" class="table" width="60%">
            <el-table-column prop="name" label="字典项名称" width="auto" min-width="20%" align="center"
              header-align="center"></el-table-column>
            <el-table-column prop="code" label="字典项代码" width="auto" min-width="20%" align="center"
              header-align="center"></el-table-column>
            <el-table-column label="操作" align="center" header-align="center" width="auto" min-width="20%">
              <template v-slot="scope">
                <div class="icon-group">
                  <el-button @click="editItem(scope.row)" type="button">
                    <el-icon size="16">
                      <Edit />
                    </el-icon>
                    编辑
                  </el-button>
                  <el-button @click="deleteItem(scope.row)" type="button">
                    <el-icon size="16">
                      <Delete />
                    </el-icon>
                    删除
                  </el-button>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Edit, Delete } from '@element-plus/icons-vue';

export default {
  components: {
    Edit,
    Delete
  },
  data() {
    return {
      types: [
        { name: '超级管理员', code: 'SampleType' },
        { name: '报告发布', code: 'SampleMode' },
        { name: '报告审核', code: 'PatientSource' },
        { name: '报告提交', code: 'MY1' },
        { name: '设备管理员', code: 'CS1' }
      ],
      items: [
        { name: '超级管理员', code: 'SampleType' },
        { name: '报告发布', code: 'SampleMode' },
        { name: '报告审核', code: 'PatientSource' },
        { name: '报告提交', code: 'MY1' },
        { name: '设备管理员', code: 'CS1' }
      ]
    };
  },
  methods: {
    addType() {
      console.log('添加类型');
      // 添加类型的逻辑
    },
    editType(type) {
      console.log('编辑类型', type);
      // 编辑类型的逻辑
    },
    deleteType(type) {
      console.log('删除类型', type);
      // 删除类型的逻辑
    },
    addItem() {
      console.log('添加字典项');
      // 添加字典项的逻辑
    },
    editItem(item) {
      console.log('编辑字典项', item);
      // 编辑字典项的逻辑
    },
    deleteItem(item) {
      console.log('删除字典项', item);
      // 删除字典项的逻辑
    }
  }
};
</script>

<style scoped>
* {
  box-sizing: border-box;
  /* 确保 padding 不会增加元素的总高度 */
}

.dictionary-management-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* 居左布局 */
  background-color: #474747;
  min-height: 100vh;
  font-size: 16px;
  line-height: 20px;
}

.dictionary-management-box {
  width: calc(100% - 20px);
  /* 减去左右padding */
  height: calc(100vh - 40px);
  /* 减去上下padding */
  box-sizing: border-box;
  display: flex;
  position: relative;
  margin: 20px 20px 20px 0px;
  position: relative;
}

.header {
  display: flex;
  align-items: center;
  padding: 20px 20px 0px 20px;
}

.title {
  color: #057AB2;
  font-size: 16px;
  font-weight: 500;
  margin-right: auto;
}

.add-button {
  margin-left: 20px;
  background-color: #057AB2;
  /* 上传模板按钮的背景色 */
  border-color: #057AB2;
}

.add-button:hover {
  margin-left: 20px;
  background-color: #005f8a;
  /* 上传模板按钮的背景色 */
  border-color: #005f8a;
}


.line {
  width: 100%;
  height: 1px;
  background-color: #ebeef5;
  margin-top: 20px;
  position: relative;
}

.left {
  width: 600px;
  border: #ebeef5 1px solid;
  border-radius: 12px;
  background-color: white;
  overflow: auto;
  position: relative;
}

.right {
  border: #ebeef5 1px solid;
  border-radius: 12px;
  background-color: white;
  flex: 1;
  margin-left: 10px;
  overflow: auto;
  position: relative;
}

.el-table th,
.el-table td {
  text-align: center;
  /* 表头和单元格内容居中对齐 */
}

.icon-group {
  display: flex;
  justify-content: center;
  gap: 10px;
  /* 图标之间的间距 */
}

.icon-group .el-button {
  border: none;
  color: #057ab2;
  background-color: transparent;
}
.icon-group .el-button+.el-button{
  margin-left:0px;
}

.icon-group .el-button:hover {
  background-color: rgba(5, 122, 178, 0.25);
  color: #057ab2;
}

.ctrl {
  width: 88px;
  height: 32px;
  background-color: #057AB2;
}

.ctrl:hover {
  background-color: #005f8a;
  /* 上传模板按钮悬停时的背景色 */
  border-color: #005f8a;
}

.table-container {
  flex: 1;
  overflow: auto;
  position: relative;
}

.custom-table {
  position: absolute;
  top: 80px;
  left: 0;
  right: 0;
  /* 为分页预留空间 */
  overflow: auto;
}

table {
  position: absolute;
  top: 0;
  left: 710px;
  right: 0;
  overflow: auto;
}
</style>
