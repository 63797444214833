<template>
  <div class="login-page">
    <div class="welcome-section">
      <span style="color: #057AB2;font-size: 36px;font-weight: 700;">Welcome</span>
      <span style="font-size: 70px;font-weight: 700;color:#45494C;">AQURE信息发布系统</span>
      <p style="font-size: 32px;font-weight: 400;color:#5E6266; ">{{ descriptionText }}</p>
      <el-button type="primary" class="help-button">
        获取帮助
        <el-icon style="margin-left:30px;"><arrow-right /></el-icon>
      </el-button>
    </div>
    <div class="login-section">
      <div class="login-card">
        <img src="@/assets/logo.png" alt="AQURE" class="logo">
        <el-tabs v-model="activeTab">
          <el-tab-pane label="账号登录" name="account">
            <el-form :model="loginForm" class="login-form" @submit.native.prevent="handleLogin" ref="loginFormRef">
              <el-form-item>
                <el-input v-model="loginForm.username" placeholder="用户名"
                  @input="removeInvalidCharacters('username', /^[a-zA-Z0-9]*$/)"></el-input>
              </el-form-item>
              <el-form-item :error="formError">
                <el-input show-password v-model="loginForm.password" type="password" placeholder="密码"
                  @input="removeInvalidCharacters('password', /^[a-zA-Z0-9!@#$%^&*]*$/)">
                </el-input>
              </el-form-item>
              <!-- <el-form-item>
                <a href="#" class="forgot-password">忘记密码?</a>
              </el-form-item> -->
              <el-form-item>
                <el-button type="primary" @click="handleLogin" class="login-button">登录</el-button>
              </el-form-item>
            </el-form>
          </el-tab-pane>
          <el-tab-pane label="CA登录" name="ca">
            <el-form :model="caLoginForm" class="login-form" @submit.native.prevent="handleCALogin"
              ref="caLoginFormRef">
              <el-form-item>
                <el-select v-model="caLoginForm.selectedCertificates" placeholder="选择证书">
                  <el-option v-for="item in certificates" :key="item.value" :label="item.label" :value="item.value" />
                </el-select>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="handleCALogin" class="login-button">登录</el-button>
              </el-form-item>
            </el-form>
          </el-tab-pane>
          <el-tab-pane label="扫码登录" name="qr">
            <div class="qr-login">
              <img src="@/assets/qrcode.png" alt="QR Code" class="qr-code">
              <p>扫描上方二维码</p>
              <el-button type="primary" class="login-button" @click="handleQRLogin">登录</el-button>
            </div>
          </el-tab-pane>

        </el-tabs>
        <div class="footer">
          <p>雷度米特医疗设备（上海）有限公司</p>
          <!-- <div class="separator"></div>
          <p>地址：上海市长宁区福泉北路518号1座3楼</p>
          <p>电话：021-60158555</p>
          <p>传真：021-60158578</p> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, ref, onMounted } from 'vue';
import { ArrowRight, View } from '@element-plus/icons-vue';
import { useAuthStore } from '@/stores/authStore';
import { useRouter } from 'vue-router';
import {sm2} from 'sm-crypto';

export default {
  name: 'Login',
  components: {
    ArrowRight,
    View
  },
  setup() {
    const authStore = useAuthStore();
    const router = useRouter();
    const activeTab = ref('account');

    const loginForm = reactive({
      username: '',
      password: ''
    });

    // 动态文字内容
    const descriptionText = ref('使用AQURE POC IT解决方案进行集中控制，获得您的床旁护理检测的完整概况并进行集中控制');

    const caLoginForm = reactive({
      selectedCertificates: 'certificate1',
    });

    const certificates = [
      { value: 'certificate1', label: '证书1' },
      { value: 'certificate2', label: '证书2' },
      { value: 'certificate3', label: '证书3' }
    ];

    const loginFormRef = ref();
    const caLoginFormRef = ref();

    const formError = ref('');
    const passwordVisible = ref(false);

    onMounted(() => {
      caLoginForm.selectedCertificates = [certificates[0].value]; // 默认选择第一个选项
      document.addEventListener('keydown', handleKeyPress);
    });

    const handleKeyPress = (event) => {
      if (event.key === 'Enter' || (event.ctrlKey && event.key === 'Enter')) {
        handleLogin();
      }
    };

    const validateUsername = (username) => {
      const usernamePattern = /^[a-zA-Z0-9]+$/;
      return usernamePattern.test(username);
    };

    const validatePassword = (password) => {
      const passwordPattern = /^[a-zA-Z0-9!@#$%^&*]+$/;
      return passwordPattern.test(password);
    };

    const handleLogin = async () => {
      formError.value = '';
      if (!loginForm.username) {
        formError.value = '用户名不能为空';
        return;
      }
      if (!validateUsername(loginForm.username)) {
        formError.value = '用户名只能包含字母和数字';
        return;
      }
      if (!loginForm.password) {
        formError.value = '密码不能为空';
        return;
      }
      if (!validatePassword(loginForm.password)) {
        formError.value = '密码只能包含字母、数字和特殊符号';
        return;
      }

      // 使用 SM2 加密密码
      const publicKey = '049b1992cb81ce0f6776b1a91a253c34405851199b36e6e99c2b39a0d76140b183635519ad2f171bc9dcd33042c62a91c795efff219d04ab1ecfd7920a8b2a0afe'; // 替换为实际的公钥
      const encryptedPassword = sm2.doEncrypt(loginForm.password, publicKey);

      try {
        const success = await authStore.login({
          username: loginForm.username,
          password: encryptedPassword
        });
        if (success.code == 200) {
          router.push({ path: '/' }); // 重定向到首页
        } else {
          formError.value = success.message;
        }
      } catch (error) {
        formError.value = '登录请求失败: ' + error.message;
        console.log(error.message);
      }
    };

    const handleCALogin = async () => {
      try {
        // Add CA login logic here
        console.log('CA Login');
      } catch (error) {
        console.error('CA 登录请求失败', error.message);
        alert('CA 登录请求失败: ' + error.message);
      }
    };

    const handleQRLogin = () => {
      console.log('扫码登录');
      // 在这里添加扫码登录的逻辑
    };

    const togglePasswordVisibility = () => {
      passwordVisible.value = !passwordVisible.value;
    };

    const removeInvalidCharacters = (field, pattern) => {
      loginForm[field] = loginForm[field].replace(new RegExp(`[^${pattern.source}]`, 'g'), '');
    };

    return {
      activeTab,
      loginForm,
      caLoginForm,
      certificates,
      loginFormRef,
      caLoginFormRef,
      handleLogin,
      handleCALogin,
      handleQRLogin,
      formError,
      passwordVisible,
      togglePasswordVisibility,
      removeInvalidCharacters,
      descriptionText
    };
  }
};
</script>

<style scoped>
.login-page {
  display: flex;
  height: 100vh;
  background: url('@/assets/background.png') no-repeat center center;
  background-size: cover;
  position: relative;
}

.welcome-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 323px;
  color: #333;
  /* 根据提供的图更改颜色 */
}

.welcome-section h2 {
  color: #007BFF;
  /* 根据提供的图更改颜色 */
  font-size: 36px;
}

.welcome-section h1 {
  font-size: 60px;
  margin: 20px 0;
  color: #333;
  /* 根据提供的图更改颜色 */
}

.welcome-section p {
  font-size: 32px;
  margin: 32px 0;
  color: #666;
  /* 根据提供的图更改颜色 */
  max-width: 680px;
  /* 设置最大宽度 */
  word-wrap: break-word;
  /* 自动换行 */
}

.help-button {
  height: 60px;
  width: 180px;
  font-size: 24px;
  line-height: 40px;
  background-color: #057AB2;
  margin-top: 40px;
}

.login-section {
  position: absolute;
  top: 50%;
  right: 92px;
  /* 距右 92px */
  transform: translateY(-50%);
  width: 400px;
  height: 800px;
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.login-card {
  width: 100%;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 60px;
  /* 顶部距离 */
}

.logo {
  width: 271px;
  /* Logo 宽度调整为 271px */
  height: 80px;
  margin-bottom: 48px;
  /* 底部距离 48px */
}

.login-form {
  width: 100%;
}

.login-form .el-form-item {
  margin-bottom: 20px;
}

.login-form .el-input,
.login-form .el-select {
  width: 320px;
  height: 48px;
  line-height: 48px;
}

.el-input .el-input__inner,
.el-select .el-input__inner {
  height: 48px;
  line-height: 48px;
}

.forgot-password {
  position: absolute;
  text-decoration: none;
  right: 0;
  color: #409EFF;
}

.login-button {
  width: 320px;
  height: 48px;
  background-color: #057AB2;
}

.footer {
  position: absolute;
  bottom: 24px;
  text-align: center;
  font-size: 14px;
  color: #5E6266;
  font-weight: 400;
  width: 100%;
}

.footer .separator {
  width: 268px;
  height: 1px;
  background-color: #ddd;
  margin: 8px auto;
}

.footer p {
  margin: 0;
}

.footer p:first-child {
  font-size: 14px;
  font-weight: bold;
  color: black;
  line-height: 24px;
}

.footer p:not(:first-child) {
  font-size: 12px;
  color: #999;
  line-height: 20px;
}

.error-message {
  color: red;
  font-size: 14px;
  text-align: left;
  margin-top: -10px;
  margin-bottom: 10px;
}

:deep(.el-tabs__item) {
  color: #8694A2;
  /** tab颜色更改*/
  font-weight: 500;
}

:deep(.el-tabs__item.is-active) {
  color: #057AB2;
  /** tab颜色更改*/
  font-weight: 500;
}

:deep(.el-button--primary) {
  background-color: #057ab2;
  border-color: #057ab2;
}

:deep(.el-button--primary:hover) {
  background-color: #045a8d;
  border-color: #045a8d;
}

/**扫码部分样式 */
.el-tabs__header {
  display: flex;
  justify-content: flex-start; /* Tab标签栏居左对齐 */
  border-bottom: 1px solid #e8e8e8;
}

.el-tabs__nav-wrap {
  flex: 1;
}

.el-tabs__nav {
  justify-content: flex-start;
  display: flex;
}

.qr-login {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.qr-code {
  width: 200px;
  height: 200px;
  margin-bottom: 20px;
}
</style>
