import { createStore } from 'vuex';

export default createStore({
  state: {
    isLoggedIn: false,
    user: null
  },
  mutations: {
    login(state, user) {
      state.isLoggedIn = true;
      state.user = user;
    },
    logout(state) {
      state.isLoggedIn = false;
      state.user = null;
    }
  },
  actions: {
    login({ commit }, user) {
      // 模拟异步登录操作
      return new Promise((resolve) => {
        setTimeout(() => {
          commit('login', user);
          resolve();
        }, 1000);
      });
    },
    logout({ commit }) {
      commit('logout');
    }
  },
  getters: {
    isLoggedIn: (state) => state.isLoggedIn,
    user: (state) => state.user
  },
  modules: {}
});
