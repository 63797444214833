<template>
  <div class="template-management-container">
    <div class="template-management-box">
      <div class="header">
        <span class="title">打印模板</span>
        <el-input v-model="search" placeholder="请输入内容" class="search-input" clearable>
          <template #prefix>
            <el-icon>
              <Search />
            </el-icon>
          </template>
        </el-input>
        <el-button type="primary" @click="showDialog = true" class="upload-button">上传模板</el-button>
      </div>
      <div class="table-container">
        <el-table :data="paginatedTemplates" class="custom-table" :header-cell-style="{ background: '#F5F6F7' }"
          :cell-style="{ padding: '12.5px 0' }">
          <el-table-column prop="column1" label="模板名称" width="auto" min-width="15%"></el-table-column>
          <el-table-column prop="column2" label="模板类型" width="auto" min-width="15%"></el-table-column>
          <el-table-column prop="column3" label="分组模板" width="auto" min-width="25%"></el-table-column>
          <el-table-column prop="column4" label="状态" width="auto" min-width="15%"></el-table-column>
          <el-table-column prop="column5" label="更新时间" width="auto" min-width="20%"></el-table-column>
          <el-table-column label="操作" width="auto" min-width="10%" align="center" header-align="center">
            <template v-slot="scope">
              <div class="icon-group">
                <el-button class="action1">
                  <el-icon size="12" @click="editTemplate(scope.row)">
                    <Edit />
                  </el-icon>
                </el-button>
                <el-button class="action1"><el-icon size="12" @click="deleteTemplate(scope.row)">
                    <Delete />
                  </el-icon>
                </el-button>
                <el-button class="action1"><el-icon size="12" @click="viewTemplate(scope.row)">
                    <View />
                  </el-icon>
                </el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="pagination-container">
        <el-pagination background layout="prev, pager, next" :total="filteredTemplates.length" :page-size="pageSize"
          @current-change="handlePageChange" class="pagination" />
        <div class="pagination-info">
          共 {{ filteredTemplates.length }} 条
        </div>
      </div>
      <el-dialog title="患者字段设置" v-model="showDialog" width="360px" style="border-radius:8px;">
        <el-form>
          <el-form-item label="模板名称">
            <el-input v-model="form.templateName" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="模板类型">
            <el-select v-model="form.templateType" placeholder="请选择">
              <el-option label="A4" value="A4"></el-option>
              <el-option label="A5" value="A5"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="分组模板">
            <el-radio-group v-model="form.groupTemplate">
              <el-radio label="是"></el-radio>
              <el-radio label="否"></el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="模板状态">
            <el-radio-group v-model="form.templateStatus">
              <el-radio label="启用"></el-radio>
              <el-radio label="禁用"></el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="showDialog = false" style="width:88px;height: 32px;">关闭</el-button>
          <el-button type="primary" class="ctrl" @click="saveTemplate">保存</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { Search, Edit, Delete, View } from '@element-plus/icons-vue';

export default {
  components: {
    Search,
    Edit,
    Delete,
    View
  },
  data() {
    return {
      search: '',
      showDialog: false,
      form: {
        templateName: '',
        templateType: 'A4', // 默认选择A4
        groupTemplate: '否',
        templateStatus: '启用'
      },
      templates: [
        // 模拟数据
        { column1: '内容1', column2: '内容2', column3: '内容3', column4: '内容4', column5: '内容5' },
        { column1: '内容6', column2: '内容7', column3: '内容8', column4: '内容9', column5: '内容10' },
        { column1: '内容11', column2: '内容12', column3: '内容13', column4: '内容14', column5: '内容15' },
        { column1: '内容16', column2: '内容17', column3: '内容18', column4: '内容19', column5: '内容20' },
        { column1: '内容21', column2: '内容22', column3: '内容23', column4: '内容24', column5: '内容25' },
        { column1: '内容26', column2: '内容27', column3: '内容28', column4: '内容29', column5: '内容30' },
        { column1: '内容31', column2: '内容32', column3: '内容33', column4: '内容34', column5: '内容35' },
        { column1: '内容36', column2: '内容37', column3: '内容38', column4: '内容39', column5: '内容40' },
        { column1: '内容41', column2: '内容42', column3: '内容43', column4: '内容44', column5: '内容45' },
        { column1: '内容46', column2: '内容47', column3: '内容48', column4: '内容49', column5: '内容50' },
        { column1: '内容51', column2: '内容52', column3: '内容53', column4: '内容54', column5: '内容55' },
        { column1: '内容56', column2: '内容57', column3: '内容58', column4: '内容59', column5: '内容60' },
        { column1: '内容61', column2: '内容62', column3: '内容63', column4: '内容64', column5: '内容65' },
        { column1: '内容66', column2: '内容67', column3: '内容68', column4: '内容69', column5: '内容70' },
        { column1: '内容71', column2: '内容72', column3: '内容73', column4: '内容74', column5: '内容75' },
        { column1: '内容76', column2: '内容77', column3: '内容78', column4: '内容79', column5: '内容80' },
        { column1: '内容81', column2: '内容82', column3: '内容83', column4: '内容84', column5: '内容85' },
        { column1: '内容86', column2: '内容87', column3: '内容88', column4: '内容89', column5: '内容90' },
        { column1: '内容91', column2: '内容92', column3: '内容93', column4: '内容94', column5: '内容95' },
        { column1: '内容96', column2: '内容97', column3: '内容98', column4: '内容99', column5: '内容100' },
        { column1: '内容101', column2: '内容102', column3: '内容103', column4: '内容104', column5: '内容105' },
        { column1: '内容106', column2: '内容107', column3: '内容108', column4: '内容109', column5: '内容110' },
        { column1: '内容111', column2: '内容112', column3: '内容113', column4: '内容114', column5: '内容115' },
        { column1: '内容116', column2: '内容117', column3: '内容118', column4: '内容119', column5: '内容120' },
        { column1: '内容121', column2: '内容122', column3: '内容123', column4: '内容124', column5: '内容125' }
      ],
      currentPage: 1,
      pageSize: 10,
    };
  },
  computed: {
    filteredTemplates() {
      if (!this.search) return this.templates;
      return this.templates.filter(template =>
        Object.values(template).some(value => value.includes(this.search))
      );
    },
    paginatedTemplates() {
      const start = (this.currentPage - 1) * this.pageSize;
      const end = this.currentPage * this.pageSize;
      return this.filteredTemplates.slice(start, end);
    }
  },
  methods: {
    uploadTemplate() {
      console.log('上传模板');
      // 上传模板的逻辑
    },
    editTemplate(template) {
      console.log('编辑模板', template);
      // 编辑模板的逻辑
    },
    deleteTemplate(template) {
      console.log('删除模板', template);
      // 删除模板的逻辑
    },
    viewTemplate(template) {
      console.log('查看模板', template);
      // 查看模板的逻辑
    },
    handlePageChange(page) {
      this.currentPage = page;
    },
    saveTemplate() {
      console.log('保存模板', this.form);
      this.showDialog = false;
      // 保存模板的逻辑
    }
  }
};
</script>

<style scoped>
* {
  box-sizing: border-box;
  /* 确保 padding 不会增加元素的总高度 */
}

.template-management-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* 居左布局 */
  background-color: #474747;
  min-height: 100vh;
  font-size: 16px;
  line-height: 20px;
}

.template-management-box {
  width: calc(100% - 20px);
  /* 减去左右padding */
  height: calc(100vh - 40px);
  /* 减去上下padding */
  background-color: #F0F3F5;
  border-radius: 12px;
  padding: 20px 20px 0px 20px;
  box-sizing: border-box;
  position: relative;
  margin: 20px 20px 20px 0px;
  /* 距离顶部20px */
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.title {
  color: #057AB2;
  font-size: 16px;
  font-weight: 500;
  margin-right: auto;
}

.search-input {
  width: 300px;
  margin-right: 20px;
}

.upload-button {
  margin-left: 20px;
  background-color: #057AB2;
  /* 上传模板按钮的背景色 */
  border-color: #057AB2;
}

.upload-button:hover {
  background-color: #005f8a;
  /* 上传模板按钮悬停时的背景色 */
  border-color: #005f8a;
}

.dialog-footer {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 10px;
}

.pagination {
  margin-top: 10px;
  display: flex;
  justify-content: center;
}

.icon-group {
  display: flex;
  gap: 2px;
}
.icon-group .action1 {
  border: none;
  color: #057ab2;
  background-color: transparent;
}
.icon-group .action1:hover {
  background-color: rgba(5, 122, 178, 0.25);
  color: #057ab2;
  border:none;
}
:deep(.icon-group .el-button+.el-button){
  margin-left: 0px;
}

.el-table th,
.el-table td {
  text-align: center;
  /* 表头和单元格内容居中对齐 */
}

.el-pagination .el-pager .el-pagination__pager li.active {
  background-color: #057AB2;
  /* 分页选中当前页背景色 */
  border-color: #057AB2;
  color: #fff;
}

.table-container {
  flex: 1;
  overflow: auto;
  position: relative;
}

.adddialog {
  border-radius: 8px;
}

.ctrl {
  width: 88px;
  height: 32px;
  background-color: #057AB2;
}

.ctrl:hover {
  background-color: #005f8a;
  /* 上传模板按钮悬停时的背景色 */
  border-color: #005f8a;
}

.custom-table {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 52px;
  /* 为分页预留空间 */
  overflow: auto;
}

.pagination-container {
  height: 52px;
  /* 与表格底部预留空间匹配 */
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
}

.pagination {
  display: flex;
  align-items: center;
}

.pagination-info {
  text-align: center;
  margin-left: 10px;
  margin-top: 10px;
}
</style>