<template>
  <div class="user-management-container">
    <div class="user-management-box">
      <div class="header">
        <span class="title">用户管理</span>
        <div class="actions">
          <el-input placeholder="请输入" v-model="searchQuery" class="search-input">
            <template #prefix>
              <el-icon>
                <Search />
              </el-icon>
            </template>
          </el-input>
          <el-button type="primary" @click="syncUsers" class="custom-button1">同步用户</el-button>
          <el-button type="primary" @click="openDialog('add')" class="custom-button">添加用户</el-button>
        </div>
      </div>
      <div class="table-container">
        <el-table :data="paginatedData" class="custom-table" :header-cell-style="{ background: '#F5F6F7' }">
          <el-table-column prop="name" label="姓名" width="auto" min-width="8%">
            <template v-slot="scope">
              <div class="name-column">
                <el-avatar :src="scope.row.avatar" class="user-avatar"></el-avatar>
                <span>{{ scope.row.name }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="jobNumber" label="工号" width="auto" min-width="7%"></el-table-column>
          <el-table-column prop="gender" label="性别" width="auto" min-width="5%"></el-table-column>
          <el-table-column prop="birthdate" label="出生日期" width="auto" min-width="10%"></el-table-column>
          <el-table-column prop="account" label="账号" width="auto" min-width="10%"></el-table-column>
          <el-table-column prop="role" label="角色" width="auto" min-width="10%">
            <template v-slot="scope">
              <span>{{ scope.row.role.join(', ') }}</span>
            </template>
          </el-table-column>
          <el-table-column label="签名" width="auto" min-width="9%">
            <template v-slot="scope">
              <img :src="scope.row.signature" class="signature-image" />
            </template>
          </el-table-column>
          <el-table-column prop="status" label="状态" width="auto" min-width="8%">
            <template v-slot="scope">
              <span :class="scope.row.status === '已发布' ? 'status-published' : ''">{{ scope.row.status }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="department" label="所属科室" width="auto" min-width="8%"></el-table-column>
          <el-table-column prop="expiryDate" label="账号有效截止日期" width="auto" min-width="9%"></el-table-column>
          <el-table-column label="操作" width="auto" min-width="12%" align="center" header-align="center">
            <template v-slot="scope">
              <div class="action-buttons">
                <el-button class="action-button" @click="editUser(scope.row)">
                  <el-icon :size="18">
                    <Edit />
                  </el-icon>
                  <span>编辑</span>
                </el-button>
                <el-button class="action-button" style="margin-left: 2px;" @click="configureUser(scope.row)">
                  <el-icon :size="14">
                    <img width="14" src="@/assets/dun.png" />
                  </el-icon>
                  <span>权限</span>
                </el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="pagination-container">
        <el-pagination background layout="prev, pager, next" :total="filteredTableData.length" :page-size="pageSize"
          @current-change="handlePageChange" class="pagination">
        </el-pagination>
        <div class="pagination-info">
          共 {{ filteredTableData.length }} 条
        </div>
      </div>
    </div>
    <el-dialog :title="dialogTitle" v-model="showDialog" width="360px" style="border-radius:8px;">
      <el-form :model="currentUser" label-width="80px">
        <el-form-item label="头像">
          <div class="avatar-container">
            <img v-if="currentUser.avatar" :src="currentUser.avatar">
            <el-upload v-else action="#" list-type="picture-card" :limit="1" :on-success="handleAvatarSuccess"
              :file-list="avatarFileList">
              <el-icon class="avatar-uploader-icon">
                <Plus />
              </el-icon>
            </el-upload>
          </div>
        </el-form-item>
        <el-form-item label="姓名">
          <el-input v-model="currentUser.name" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="工号">
          <el-input v-model="currentUser.jobNumber" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="性别">
          <el-radio-group v-model="currentUser.gender">
            <el-radio label="男"></el-radio>
            <el-radio label="女"></el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="生日">
          <el-date-picker v-model="currentUser.birthdate" type="date" placeholder="选择日期"></el-date-picker>
        </el-form-item>
        <el-form-item label="账号">
          <el-input v-model="currentUser.account" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="密码">
          <el-input v-model="currentUser.password" type="password" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="失效日期">
          <el-date-picker v-model="currentUser.expiryDate" type="date" placeholder="选择日期"></el-date-picker>
        </el-form-item>
        <el-form-item label="角色">
          <el-select v-model="currentUser.role" multiple placeholder="请选择角色">
            <el-option label="管理员" value="管理员"></el-option>
            <el-option label="普通用户" value="普通用户"></el-option>
            <!-- 其他角色选项根据需要添加 -->
          </el-select>
        </el-form-item>
        <el-form-item label="签名照片">
          <div class="signature-container">
            <img v-if="currentUser.signature" :src="currentUser.signature">
            <el-upload v-else action="#" list-type="picture" :limit="1" :on-success="handleSignatureSuccess"
              :file-list="signatureFileList">
              <el-button size="small" type="primary">点击上传</el-button>
            </el-upload>
          </div>
        </el-form-item>
        <el-form-item label="所属科室">
          <el-select v-model="currentUser.department" placeholder="请选择">
            <el-option label="技术部" value="技术部"></el-option>
            <el-option label="市场部" value="市场部"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="showDialog = false" class="footer-button">关闭</el-button>
        <el-button type="primary" @click="saveUser" class="footer-button">保存</el-button>
      </div>
    </el-dialog>

    <el-dialog title="数据权限设置" v-model="showPermissionsDialog" width="620px">
      <div class="permissions-container">
        <div class="permissions-column">
          <h3>科室设备权限</h3>
          <hr class="full-width-line">
          <el-scrollbar style="min-height: 400px; max-height: 400px;">
            <el-tree :data="departmentTree" show-checkbox node-key="id" default-expand-all :props="defaultProps"
              v-model="currentPermissions.departments"></el-tree>
          </el-scrollbar>
        </div>
        <div class="permissions-column" style="margin-left:10px;">
          <h3>报告字段编辑权限</h3>
          <hr class="full-width-line">
          <el-scrollbar style="min-height: 400px; max-height: 400px;">
            <el-checkbox v-model="isAllChecked" @change="toggleAllFields">全选</el-checkbox>
            <el-checkbox-group v-model="currentPermissions.fields">
              <el-checkbox label="患者ID"></el-checkbox>
              <el-checkbox label="患者姓名"></el-checkbox>
              <el-checkbox label="患者来源"></el-checkbox>
              <el-checkbox label="住院号"></el-checkbox>
              <el-checkbox label="条码号"></el-checkbox>
              <el-checkbox label="卡号"></el-checkbox>
              <el-checkbox label="床号"></el-checkbox>
              <el-checkbox label="性别"></el-checkbox>
              <el-checkbox label="出生日期"></el-checkbox>
              <el-checkbox label="诊断"></el-checkbox>
              <el-checkbox label="患者描述"></el-checkbox>
              <el-checkbox label="联系地址"></el-checkbox>
              <el-checkbox label="联系方式"></el-checkbox>
              <el-checkbox label="采样时间"></el-checkbox>
              <el-checkbox label="申请医生"></el-checkbox>
              <el-checkbox label="样本类型"></el-checkbox>
              <el-checkbox label="采样模式"></el-checkbox>
              <el-checkbox label="接收时间"></el-checkbox>
              <el-checkbox label="检测时间"></el-checkbox>
              <el-checkbox label="样本说明"></el-checkbox>
              <el-checkbox label="检测医生"></el-checkbox>
            </el-checkbox-group>
          </el-scrollbar>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="showPermissionsDialog = false" class="footer-button">关闭</el-button>
        <el-button type="primary" @click="savePermissions" class="footer-button">保存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { Edit, Setting, Plus, Check, Close, Search } from '@element-plus/icons-vue';

export default {
  name: 'UserManagement',
  components: {
    Edit,
    Setting,
    Plus,
    Check,
    Close,
    Search
  },
  data() {
    return {
      searchQuery: '',
      showDialog: false,
      showPermissionsDialog: false,
      dialogTitle: '添加用户',
      dialogVisible: false,
      dialogImageUrl: '',
      currentUser: {
        avatar: '',
        name: '',
        jobNumber: '',
        gender: '',
        birthdate: '',
        account: '',
        password: '',
        expiryDate: '',
        role: [],
        signature: '',
        department: ''
      },
      currentPermissions: {
        departments: [],
        fields: ['患者ID', '患者姓名', '患者来源', '住院号', '条码号', '卡号', '床号', '性别', '出生日期', '诊断', '患者描述', '联系地址', '联系方式', '采样时间', '申请医生', '样本类型', '采样模式', '接收时间', '检测时间', '样本说明', '检测医生']
      },
      isAllChecked: true,
      avatarFileList: [],
      signatureFileList: [],
      tableData: [
        { name: '郭艾伦', jobNumber: '001', gender: '男', birthdate: '1990-01-01', account: 'admin', role: ['管理员'], signature: 'https://via.placeholder.com/120x36', avatar: 'https://via.placeholder.com/30', status: '已发布', department: '技术部', expiryDate: '2023-12-31' },
        { name: '张伟', jobNumber: '002', gender: '男', birthdate: '1985-05-15', account: 'zhangwei', role: ['普通用户'], signature: 'https://via.placeholder.com/120x36', avatar: 'https://via.placeholder.com/30', status: '未发布', department: '市场部', expiryDate: '2023-12-31' },
        { name: '李娜', jobNumber: '003', gender: '女', birthdate: '1992-07-20', account: 'lina', role: ['普通用户'], signature: 'https://via.placeholder.com/120x36', avatar: 'https://via.placeholder.com/30', status: '已发布', department: '技术部', expiryDate: '2023-12-31' },
        { name: '王强', jobNumber: '004', gender: '男', birthdate: '1988-11-10', account: 'wangqiang', role: ['管理员'], signature: 'https://via.placeholder.com/120x36', avatar: 'https://via.placeholder.com/30', status: '未发布', department: '市场部', expiryDate: '2023-12-31' },
        { name: '赵敏', jobNumber: '005', gender: '女', birthdate: '1995-03-25', account: 'zhaomin', role: ['普通用户'], signature: 'https://via.placeholder.com/120x36', avatar: 'https://via.placeholder.com/30', status: '已发布', department: '技术部', expiryDate: '2023-12-31' },
        { name: '孙浩', jobNumber: '006', gender: '男', birthdate: '1987-09-05', account: 'sunhao', role: ['普通用户'], signature: 'https://via.placeholder.com/120x36', avatar: 'https://via.placeholder.com/30', status: '未发布', department: '市场部', expiryDate: '2023-12-31' },
        { name: '周杰', jobNumber: '007', gender: '男', birthdate: '1991-12-15', account: 'zhoujie', role: ['管理员'], signature: 'https://via.placeholder.com/120x36', avatar: 'https://via.placeholder.com/30', status: '已发布', department: '技术部', expiryDate: '2023-12-31' },
        { name: '吴芳', jobNumber: '008', gender: '女', birthdate: '1993-06-30', account: 'wufang', role: ['普通用户'], signature: 'https://via.placeholder.com/120x36', avatar: 'https://via.placeholder.com/30', status: '未发布', department: '市场部', expiryDate: '2023-12-31' },
        { name: '陈思', jobNumber: '009', gender: '女', birthdate: '1994-08-22', account: 'chensi', role: ['普通用户'], signature: 'https://via.placeholder.com/120x36', avatar: 'https://via.placeholder.com/30', status: '已发布', department: '技术部', expiryDate: '2023-12-31' },
        { name: '杨洋', jobNumber: '010', gender: '男', birthdate: '1996-04-18', account: 'yangyang', role: ['管理员'], signature: 'https://via.placeholder.com/120x36', avatar: 'https://via.placeholder.com/30', status: '未发布', department: '市场部', expiryDate: '2023-12-31' },
        { name: '刘涛', jobNumber: '011', gender: '女', birthdate: '1989-10-08', account: 'liutao', role: ['普通用户'], signature: 'https://via.placeholder.com/120x36', avatar: 'https://via.placeholder.com/30', status: '已发布', department: '技术部', expiryDate: '2023-12-31' },
        { name: '黄磊', jobNumber: '012', gender: '男', birthdate: '1986-07-12', account: 'huanlei', role: ['普通用户'], signature: 'https://via.placeholder.com/120x36', avatar: 'https://via.placeholder.com/30', status: '未发布', department: '市场部', expiryDate: '2023-12-31' },
        { name: '徐静蕾', jobNumber: '013', gender: '女', birthdate: '1997-02-28', account: 'xujinglei', role: ['管理员'], signature: 'https://via.placeholder.com/120x36', avatar: 'https://via.placeholder.com/30', status: '已发布', department: '技术部', expiryDate: '2023-12-31' },
        { name: '韩寒', jobNumber: '014', gender: '男', birthdate: '1998-09-14', account: 'hanhan', role: ['普通用户'], signature: 'https://via.placeholder.com/120x36', avatar: 'https://via.placeholder.com/30', status: '未发布', department: '市场部', expiryDate: '2023-12-31' },
        { name: '郭敬明', jobNumber: '015', gender: '男', birthdate: '1999-11-23', account: 'guojingming', role: ['普通用户'], signature: 'https://via.placeholder.com/120x36', avatar: 'https://via.placeholder.com/30', status: '已发布', department: '技术部', expiryDate: '2023-12-31' },
        // Add more rows as needed
      ],
      departmentTree: [
        {
          id: 1,
          label: '威海市立医院',
          children: [
            {
              id: 2, label: '心外科(和平)', children: [
                { id: 3, label: '心外科(和平)', children: [] }
              ]
            },
            {
              id: 4, label: '重症医学科(临港)',
              children: [
                { id: 5, label: '重症血气(临港)', children: [] },
                { id: 6, label: '重症免疫(临港)', children: [] },
                { id: 7, label: '重症免疫(临港)', children: [] },
              ]
            },
            { id: 8, label: '产房(和平路)', children: [{ id: 5, label: '产房血气(和平路)', children: [] }] },
            {
              id: 9,
              label: '急诊内一科门诊(临港)',
              children: [
                { id: 10, label: '急诊内一免疫(临港)', children: [] },
                { id: 11, label: '急诊内一血气(临港)', children: [] }
              ]
            },
            {
              id: 9, label: '重症医学科病区(和平路)', children: [
                { id: 12, label: 'ICU血气(和平)', children: [] },
                { id: 13, label: 'ICU心标(和平)', children: [] }

              ]
            },
            { id: 14, label: '心内一科(和平路)', children: [] }
          ]
        }
      ],
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      currentPage: 1,
      pageSize: 10,
    };
  },
  computed: {
    filteredTableData() {
      if (!this.searchQuery) {
        return this.tableData;
      }
      return this.tableData.filter(user => {
        return user.name.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
          user.jobNumber.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
          user.account.toLowerCase().includes(this.searchQuery.toLowerCase());
      });
    },
    paginatedData() {
      const start = (this.currentPage - 1) * this.pageSize;
      const end = this.currentPage * this.pageSize;
      return this.filteredTableData.slice(start, end);
    }
  },
  methods: {
    syncUsers() {
      // 同步用户处理逻辑
      console.log('Sync users');
    },
    addUser() {
      // 添加用户处理逻辑
      console.log('Add user');
    },
    editUser(user) {
      // 编辑用户处理逻辑
      console.log('Edit user:', user);
      this.currentUser = { ...user };
      this.avatarFileList = user.avatar ? [{ url: user.avatar }] : [];
      this.signatureFileList = user.signature ? [{ url: user.signature }] : [];
      this.openDialog('edit');
    },
    configureUser(user) {
      // 配置用户权限处理逻辑
      console.log('Configure user:', user);
      this.currentPermissions = {
        departments: [],  // 根据实际数据进行初始化
        fields: ['患者ID', '患者姓名', '患者来源', '住院号', '条码号', '卡号', '床号', '性别', '出生日期', '诊断', '患者描述', '联系地址', '联系方式', '采样时间', '申请医生', '样本类型', '采样模式', '接收时间', '检测时间', '样本说明', '检测医生']
      };
      this.isAllChecked = true;
      this.showPermissionsDialog = true;
    },
    deleteUser(user) {
      // 删除用户处理逻辑
    },
    handlePageChange(page) {
      this.currentPage = page;
    },
    openDialog(type, user = null) {
      if (type === 'edit') {
        this.dialogTitle = '编辑用户';
        this.currentUser = { ...user };
      } else {
        this.dialogTitle = '添加用户';
        this.currentUser = {
          avatar: '',
          name: '',
          jobNumber: '',
          gender: '',
          birthdate: '',
          account: '',
          password: '',
          expiryDate: '',
          role: [],
          signature: '',
          department: ''
        };
        this.avatarFileList = [];
        this.signatureFileList = [];
      }
      this.showDialog = true;
    },
    saveUser() {
      console.log('保存用户:', this.currentUser);
      this.showDialog = false;
    },
    savePermissions() {
      console.log('保存权限:', this.currentPermissions);
      this.showPermissionsDialog = false;
    },
    toggleAllFields() {
      if (this.isAllChecked) {
        this.currentPermissions.fields = ['患者ID', '患者姓名', '患者来源', '住院号', '条码号', '卡号', '床号', '性别', '出生日期', '诊断', '患者描述', '联系地址', '联系方式', '采样时间', '申请医生', '样本类型', '采样模式', '接收时间', '检测时间', '样本说明', '检测医生'];
      } else {
        this.currentPermissions.fields = [];
      }
    },
    handleAvatarSuccess(response, file) {
      this.currentUser.avatar = URL.createObjectURL(file.raw);
      this.avatarFileList = [{ url: this.currentUser.avatar }];
    },
    handleSignatureSuccess(response, file) {
      this.currentUser.signature = URL.createObjectURL(file.raw);
      this.signatureFileList = [{ url: this.currentUser.signature }];
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    }
  }
};
</script>

<style scoped>
* {
  box-sizing: border-box;
}

.user-management-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: #474747;
  min-height: 100vh;
  font-size: 16px;
  line-height: 20px;
}

.user-management-box {
  width: calc(100% - 20px);
  height: calc(100vh - 40px);
  background-color: #F0F3F5;
  border-radius: 12px;
  padding: 20px 20px 0 20px;
  box-sizing: border-box;
  position: relative;
  margin: 20px 20px 20px 0px;
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  color: #057AB2;
}

.header h1 {
  margin: 0;
  font-size: 20px;
  font-weight: bold;
}

.actions {
  display: flex;
  align-items: center;
}

.search-input {
  margin-right: 10px;
}

.custom-button1 {
  background-color: white;
  color: black;
  border-color: #F0F3F5;
}

.custom-button {
  background-color: #057AB2;
  color: white;
  border:none;
}

.custom-button:hover {
  background-color: #045a8d;
  border:none;
}

.name-column {
  display: flex;
  align-items: center;
}

.user-avatar {
  margin-right: 8px;
  width: 30px;
  height: 30px;
}

.custom-table {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 52px;
  overflow: auto;
}

.action-icon {
  cursor: pointer;
}

.pagination {
  display: flex;
  justify-content: center;
}

.title {
  color: #057AB2;
  font-size: 16px;
  font-weight: 500;
  margin-right: auto;
}

.status-published {
  color: #56B22B;
}

.dialog-footer {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
}

.action-buttons .action-button {
  border: none;
  color: #057AB2;
  width: 68px;
}

:deep(.avatar-container .el-upload--picture-card),
:deep(.avatar-container .el-upload-list__item),
:deep(.avatar-container .el-upload-list__item-actions) {
  width: 48px;
  height: 48px;
}
:deep(.signature-container  .el-upload--picture-card,.el-upload-list__item.el-upload-list__item-actions){
  height:20px;
}
:deep(.signature-container .el-upload-list--picture .el-upload-list__item-thumbnail){
  height: 20px;
}

.action-button:hover {
  background-color: rgba(5, 122, 178, 0.25);
  color: #057AB2;
}

:deep(.el-table--enable-row-hover .el-table__body tr:hover > td) {
  background-color: rgba(0, 0, 0, 0) !important;
}

.pagination-container {
  height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.pagination-info {
  text-align: center;
  margin-left: 10px;
}

.table-container {
  flex: 1;
  overflow: auto;
  position: relative;
}

.permissions-container {
  display: flex;
  border-radius: 8px;
}

.permissions-column {
  background-color: #F0F3F5;
  border: #F0F3F5 1px solid;
  border-radius: 8px;
  flex: 1;
  padding: 0px 10px 10px 10px;
  min-height: 300px;
}

.permissions-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.full-width-line {
  width: 100%;
  border: none;
  border-bottom: 1px solid #DCDCDC;
  margin: 10px 0;
}

.footer-button {
  width: 88px;
  height: 32px;
}
:deep(.el-dialog__title){
  font-size: 20px;
  font-weight: 700;
}
</style>
