import axios from 'axios';
import {useAuthStore } from '@/stores/authStore'

const apiClient = axios.create({
  //baseURL: 'http://127.0.0.1:8000',
  baseURL: 'http://leidu.jiaoyidazhuo.com',
  headers: {
    'Content-Type': 'application/json',
  },
  timeout:1000000,
});
// 请求拦截器
apiClient.interceptors.request.use(
  (config) => {
    const authStore = useAuthStore();
    // 如果请求不是登录请求，则添加 token
    if (!config.url.includes('/auth/login')) {
      if (authStore.getToken) {
        config.headers.Authorization = `${authStore.getToken}`;
      }
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
export default apiClient;
