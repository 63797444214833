<template>
  <div class="role-management-container">
    <div class="left-panel">
      <div class="header">
        <span class="title">角色列表</span>
        <el-button @click="showRoleDialog(null)" class="add-role-button">添加角色</el-button>
      </div>
      <el-divider class="divider" />
      <el-table :data="roles" style="width: 100%;" :show-header="false" @row-click="selectRole"
        :row-class-name="getRowClass">
        <el-table-column prop="name" label="角色名称">
          <template v-slot="scope">
            <span>{{ scope.row.name }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" header-align="center">
          <template v-slot="scope">
            <div class="action-buttons">
              <el-button class="action-button"><el-icon size="14" color="#057AB2"
                  @click.stop="showRoleDialog(scope.row)">
                  <Edit />
                </el-icon></el-button>
              <el-button class="action-button"><el-icon size="14" color="#057AB2" @click.stop="deleteRole(scope.row)">
                  <Delete />
                </el-icon></el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    
    <div class="right-panel" v-if="currentRole">
      <div class="right-title">
        <span class="title">权限设置</span>
      </div>
      <hr class="divider">   
      <el-scrollbar class="right-panel">
      <div class="permission-section">
        <div v-for="item in currentRole.permissions" :key="item.label" class="permission-category">
          <el-checkbox v-model="item.checked" :indeterminate="item.indeterminate" @change="updateChildCheckbox(item)">
            {{ item.label }}
          </el-checkbox>
          <div class="permissions">
            <div v-for="child in item.children" :key="child.label"
              :class="{ 'permission-item': true, 'with-background': child.children.length }">
              <el-checkbox v-model="child.checked" :indeterminate="child.indeterminate"
                @change="updateChildCheckbox(child)">
                {{ child.label }}
              </el-checkbox>
              <div v-if="child.children.length" class="sub-permissions">
                <div v-for="sub in child.children" :key="sub.label" class="sub-permission">
                  <el-checkbox v-model="sub.checked" :indeterminate="sub.indeterminate"
                    @change="updateChildCheckbox(sub)">
                    {{ sub.label }}
                  </el-checkbox>
                  <span v-if="sub.children && sub.children.length" class="separator"><el-icon>
                      <CaretRight />
                    </el-icon></span>
                  <div v-for="leaf in sub.children" :key="leaf.label" class="leaf-permission">
                    <el-checkbox v-model="leaf.checked" @change="updateChildCheckbox(leaf)">{{ leaf.label
                      }}</el-checkbox>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-scrollbar>
      <div class="save-button-container">
        <el-button type="primary" @click="saveSettings" class="save-button">保存设置</el-button>
      </div>  
    </div>
  

    <!-- 弹出窗口组件 -->
    <role-dialog v-if="dialogVisible" :role="currentRole" @close="dialogVisible = false" @save="saveRole"></role-dialog>
  </div>
</template>

<script>
import { Plus, Edit, Delete, CaretRight } from '@element-plus/icons-vue';
import RoleDialog from '@/components/RoleDialog.vue'; // 引入弹出窗口组件
import { useAuthStore } from '@/stores/authStore'; // 引入authStore以使用API

export default {
  components: {
    Plus,
    Edit,
    Delete,
    RoleDialog,
    CaretRight
  },
  data() {
    return {
      roles: [], // 角色列表通过API获取
      dialogVisible: false,
      currentRole: null
    };
  },
  async mounted() {
    // 从 API 获取角色列表
    const authStore = useAuthStore();
    try {
      const roleData = await authStore.getRoleList();
      if (roleData && roleData.data) {
        // 确保 roleData.data 存在并且是数组
        this.roles = roleData.data.map((role) => {
          // 尝试解析 permission 字段
          let permissions = { menus: [] }; // 默认空权限结构
          if (role.permission) {
            try {
              permissions = JSON.parse(role.permission); // 解析 permission JSON 字符串
            } catch (e) {
              console.error("解析权限时出错", e); // 如果 JSON 解析失败，记录错误
            }
          }

          // 确保 permissions.menus 是数组
          const permissionMenus = Array.isArray(permissions.menus) ? permissions.menus : [];

          return {
            name: role.roleName, // 显示角色名称
            permissions: this.buildPermissionTree(permissionMenus) // 构建权限树
          };
        });

        if (this.roles.length > 0) {
          this.selectRole(this.roles[0]);
        }
      } else {
        console.error("角色列表为空或格式不正确");
        this.roles = [];
      }
    } catch (error) {
      console.error("获取角色失败", error);
      this.roles = [];
    }
  },
  methods: {
    buildPermissionTree(items) {
      if (!Array.isArray(items)) return [];
      console.log("当前处理的权限项:", items);
      return items.map(item => {
        // 检查 children 和 elements，并递归处理
        const children = item.children ? this.buildPermissionTree(item.children) : [];
        const elements = item.elements ? this.buildPermissionTree(item.elements) : [];

        return {
          label: item.name, // 权限名称
          checked: item.hasPermission || false, // 根据 hasPermission 判断是否勾选
          indeterminate: false, // 默认不确定状态
          children: [...children, ...elements] // 合并 children 和 elements
        };
      });
    },
    selectRole(role) {
      this.currentRole = role;
      this.currentRole.permissions = JSON.parse(JSON.stringify(role.permissions)); // 深拷贝权限树
    },
    showRoleDialog(role) {
      this.currentRole = role ? { ...role } : { name: '', permissions: this.getRandomPermissions() };
      this.dialogVisible = true;
    },
    deleteRole(role) {
      const index = this.roles.indexOf(role);
      if (index !== -1) {
        this.roles.splice(index, 1);
      }
      if (this.currentRole === role) {
        this.currentRole = this.roles.length > 0 ? this.roles[0] : null;
      }
    },
    saveRole(role) {
      const index = this.roles.indexOf(this.currentRole);
      if (index !== -1) {
        this.roles.splice(index, 1, role);
      } else {
        this.roles.push(role);
      }
      this.dialogVisible = false;
      this.selectRole(role);
    },
    saveSettings() {
      console.log('Selected Permissions:', JSON.stringify(this.currentRole.permissions));
    },
    updateChildCheckbox(item) {
      if (item.children) {
        item.children.forEach(child => {
          child.checked = item.checked;
          this.updateChildCheckbox(child);
        });
      }
      this.updateIndeterminateState(item);
    },
    updateIndeterminateState(item) {
      if (item.children.length) {
        const allChecked = item.children.every(child => child.checked);
        const someChecked = item.children.some(child => child.checked);
        item.indeterminate = !allChecked && someChecked;
      }
    },
    getRowClass({ row }) {
      return row === this.currentRole ? 'selected-row' : '';
    }
  }
};
</script>

<style scoped>
* {
  box-sizing: border-box;
}

.role-management-container {
  display: flex;
  flex-direction: row;
  height: calc(100vh - 40px);
  margin: 20px 20px 20px 0px;
  background-color: #f2f2f2;
  border-radius: 12px;
}

.left-panel {
  background-color: white;
  width: 240px;
  border-right: 1px solid #e0e0e0;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
  overflow: hidden;
}

.right-panel {
  flex: 1;
  overflow-y:hidden;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  position: relative;
  padding-bottom: 60px;
  background-color: white;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
}

.header {
  display: flex;
  align-items: center;
  padding: 20px 12px 12px 20px;
}

.add-role-button {
  cursor: pointer;
  font-size: 16px;
  color: white;
  background-color: #057AB2;
  border-color: #057AB2;
  border-radius: 4px;
  padding: 0 12px;
  height: 32px;
  line-height: 32px;
  width: 88px;
}

.add-role-button:hover {
  background-color: #005f8a;
}

.action-buttons {
  display: flex;
  justify-content: space-evenly;
}

.permission-section {
  padding: 0 20px;
}

.permission-category {
  margin-bottom: 20px;
}

.permissions {
  margin-left: 20px;
}

.permission-item {
  margin: 5px 0;
  display: block;
  padding: 5px 5px;
  width: 772px;
}

.with-background {
  background-color: #F0F3F5;
}

.sub-permission {
  display: inline-block;
  margin-right: 10px;
}

.leaf-permission {
  display: inline-block;
  margin-right: 10px;
}

.sub-permissions {
  margin-left: 20px;
}

.separator {
  margin-left: 5px;
  margin-right: 5px;
}

.save-button-container {
  position: absolute;
  bottom: 20px;
  right: 20px;
  display: flex;
  justify-content: flex-end;
}

.save-button {
  cursor: pointer;
  font-size: 16px;
  color: white;
  background-color: #057AB2;
  border-color: #057AB2;
  border-radius: 4px;
  padding: 0 12px;
  height: 32px;
  line-height: 32px;
  width: 88px;
  margin-top: 20px;
}

.save-button:hover {
  background-color: #005f8a;
}

.divider {
  margin: 0;
  border: none;
  border-top: 1px solid #e0e0e0;
}

.title {
  color: #057AB2;
  font-size: 16px;
  font-weight: 500;
  margin-right: auto;
}

.right-title {
  padding: 20px;
}

:deep(.el-table td.el-table__cell),
:deep(.el-table th.el-table__cell.is-leaf) {
  border-bottom: none;
}

:deep(.el-table .cell) {
  line-height: 32px;
}

:deep(.el-table__inner-wrapper:before) {
  height: 0;
}

:deep(.selected-row) {
  background-color: #f0f8ff !important;
}

:deep(.action-buttons .el-button+.el-button) {
  margin-left: 0px;
}

.action-button {
  border: none;
}

.action-button:hover {
  border: none;
  background-color: rgba(5, 122, 178, 0.25);
  color: #057ab2;
}
</style>
